import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const HOST_DIR = ""

const routes = [
    {
        path: '/',
        meta: {
            title: '鹰眼智数'
        },
        component: () => import('/src/pages/index.vue'),
    },
]

const router = new Router({
    base: `/${HOST_DIR}`,
    routes: routes,
    mode: process.env.NODE_ENV === 'development' ? 'hash' : 'history'
})

export default router