import config from "./config"
import axios from "axios"

const baseUrl = process.env.NODE_ENV === 'development' 
              ? config.apiBaseUrl.dev 
              : config.apiBaseUrl.pro

class HttpRequest {

  constructor(baseUrl) {
		this.baseUrl = baseUrl
	}

  getInsideConfig() {
		return {
			baseURL: this.baseUrl,
			headers: {}
		}
	}

  interceptors(instance) {
    // 请求拦截器
    instance.interceptors.request.use(
      config => {
        // 可以在这里添加请求头等信息
        // if (store.getters.token) {
        //   config.headers['X-Token'] = getToken();
        // }
        let accessToken = localStorage.getItem('access_token');
        if(accessToken){
          config.headers['accessToken'] = accessToken;
        }

        return config;
      },
      error => {
        // 请求错误处理
        Promise.reject(error);
      }
    );


    // 响应拦截器
    instance.interceptors.response.use(
      response => {
        // 对响应数据做处理，例如只返回data部分
        const res = response.data;
        return res;
      },
      error => {
        console.log('err' + error);
        return Promise.reject(error);
      }
    );

  }


  request(options) {
    const instance = axios.create()
    options = Object.assign(this.getInsideConfig(), options)
    this.interceptors(instance)

    return instance(options)
  }
}

export default new HttpRequest(baseUrl)



   