import Vue from 'vue'
import App from './App.vue'
import ViewUI from 'view-design';
import axios from "./lib/axios";
import router from './router'
import * as echarts from 'echarts'

import 'view-design/dist/styles/iview.css';
import './assets/css/main.css';
import './assets/css/hljs.css';

Vue.use(ViewUI);

Vue.prototype.$axios = axios
Vue.prototype.$echarts = echarts;

Vue.config.productionTip = false
new Vue({
  render: h => h(App),
  router
}).$mount('#app')
